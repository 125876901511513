import React from "react";
import Layout from "../components/layout-contents";
import styles from "../styles/review.module.css";
import Container from "../components/container";
import { graphql } from "gatsby";

const User = (props) => (
  <div className={styles.topUser}>
    <div className={styles.descUser}>
      <img
        src="/images/about/giovanna/giovanna_logo.png"
        className={styles.logoUser}
        alt=""
      />
      <p className={styles.excerpt}>
      Italian professor and poet Giovanna
Demarchi studied classical literature
in Bologna. For more than ten years
her poems have described her
idiosincratic take on what separates
body, nature and mind.
      </p>
      <div className={styles.linkUser} >
        <h3 style={{ marginLeft: "0px" , marginBottom: "0px"}} ><a href="mailto:giodema94@gmail.com">CONTACT</a></h3>
      </div>
    </div>
    <a href={props.file} target="_blank" rel="noreferrer" style={{ display: "contents" }}>
      <img
        src="/images/about/giovanna/giovanna_image.png"
        className={styles.imageUser}
        style={{ width: "calc(5rem + 15.5vmin)" }}
        alt=""
      />
    </a>
  </div>
);

const Post = (props) => (
  <div style={{  marginBottom:"70px"}}>
    
        <p style={{ whiteSpace: "pre-line"}} >
        <b>{props.title}</b> <br /> <br />
          {props.excerpt}</p>
    </div>
);

/*
  <h1>About CSS Modules</h1>
    <p>CSS Modules are cool</p>
*/
export default ({ data }) => (
  <Layout>
    <Container>
      <User></User>
      {data.allGiovannaJson.edges.map(({ node }) => (
        <Post
          title={node.title}
          excerpt={node.excerpt}
        />
      ))}
    </Container>
  </Layout>
);

export const query = graphql`
  query {
    allGiovannaJson {
      edges {
        node {
          title
          excerpt
        }
      }
    }
  }
`;
